export const navItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-dashboard'
  },
  {
    title: true,
    name: 'Consumptions'
  },
  {
    name: 'Consumption',
    url: '/consumption',
    icon: 'fa fa-tint',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  }
];
