import { Component } from '@angular/core';
import { navItems } from './../../_nav';
import { environment } from '../../../environments/environment';

import { TranslationService } from '../../services/translation/translation.service'
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  currentDate = new Date();
  public env = environment;
  public currentYear = this.currentDate.getFullYear();
  currentLanguage: string = 'en';

  constructor(private translationService: TranslationService, private translate: TranslateService) {
    this.changes = new MutationObserver(() => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(this.element, {
      attributes: true
    });
  }

  onChangeLanguage(language: string) {
    this.translate.setDefaultLang(language)
    /* navItems.forEach(item => {
      console.log(item)
      this.translate.get(item.name)
        .subscribe((text) => {          
          if (text.title !== undefined) {
            item.name = text.title
          }
        });
    }) */
    this.currentLanguage = language;
    this.translationService.changeLanguage(language);
  }
}
