import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import {TranslateService} from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['../../node_modules/ladda/dist/ladda-themeless.min.css']
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private translate: TranslateService) {
    translate.setDefaultLang('en');
  }
  
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  
  useLanguage(language: string) {
    this.translate.use(language);
  }
}
