import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) { }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.api.url}/auth`, { login: { username, password } })
        .pipe(map(request => {
            // login successful if there's a jwt token in the response
            if (request && request.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', request.token);
            }                
            
            return request;
        }));
  }
  
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
  }
}
