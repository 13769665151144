import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  @Output() onChangeLanguage: EventEmitter<string> = new EventEmitter();

  constructor() { }

  changeLanguage (language: string) {
    this.onChangeLanguage.emit(language)
  }
}
