import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard} from './guards/auth.guard'

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { LoginComponent } from './views/login/login.component';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { NotFound404Component } from './views/error/404/404.component';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full', canActivate: [ AuthGuard ] },
  { path: '', component: DefaultLayoutComponent, data: { title: 'Home' }, canActivate: [ AuthGuard ],
    children: [ 
      { path: 'dashboard', loadChildren: './views/dashboard/dashboard.module#DashboardModule' },
      { path: 'buttons', loadChildren: './views/buttons/buttons.module#ButtonsModule' },
      { path: 'consumption', loadChildren: './views/consumption/consumption.module#ConsumptionModule' }
    ]
  },
  { path: 'login', component: LoginComponent, data: { title: 'Login Page' }},
  
  // otherwise redirect to home
  { path: '404', component: NotFound404Component },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
